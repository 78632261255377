.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.table-row {
    border-bottom: 1px solid #808080; /* Change color as needed */
}
.available-order-item {
    border-bottom: 1px solid gray;
}
img {
    max-width: 100%;
    height: auto;
}


.pageMarginMgr {
    margin-left: 40px;
    margin-right: 40px;
}

.pageMargin {
    margin-left: 250px;
    margin-right: 250px;
}
/* Add media query for smaller screen widths */
@media screen and (max-width: 780px) {
    .pageMargin {
        margin-left: 20px;
        margin-right: 20px;
    }
}
/* Add media query for smaller screen widths */
@media screen and (max-width: 1500px) {
    .pageMargin {
        margin-left: 20px;
        margin-right: 20px;
    }
}

button:disabled {
 
  opacity: 0.5; /* makes button appear faded */
  cursor: not-allowed; /* changes cursor to indicate that the button is disabled */
}

.employeeInfo {
    text-align: left;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
}
.header, .order-summary {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
}

.App-link {
  color: #61dafb;
}
.row-container {
  display: flex;
  justify-content: space-between;
}
 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Original table styling */
table {
  width: 100%;
}